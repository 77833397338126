import {
  AppointmentResponse,
  FetchResponse,
  IForgotPasswordReset,
  IForgotPasswordResetRequest,
  IRegisterAccount,
  IUpdatePassword,
  IVerifyEmail,
  SlotResponse,
} from 'web-app';
import config from 'web-app/config/environment';
import type { Profile, User } from 'web-app/services/current-user';
import { CaseModel, Convert } from 'web-app/utils/caseModel';
import { action } from '@ember/object';
import Service from '@ember/service';
import { Registry, service } from '@ember/service';
import { DateTime } from 'luxon';
import { Assessment } from 'web-app/utils/AppointmentModel';
interface QuestionAnswer {
  questionId: string;
  answerId: string;
}
export type ApiHeaders = Record<string, string>;

export class FetchError extends Error {
  public json!: Record<string, unknown>;
  public statusText!: string;
  public status!: number;
  public headers!: Headers;
  public text!: string;

  constructor(res: FetchResponse) {
    super(<string>res?.json?.message);
    this.json = res?.json || {
      message: 'We were unable to communicate with the Arian Wellbeing Servers, please try again in a few minutes.',
    };
    this.statusText = res.statusText;
    this.status = res.status;
    this.headers = res.headers;
    this.text = res.text;
    this.name = 'FetchError';
    Object.setPrototypeOf(this, FetchError.prototype);
  }
}

const emptyStringToNull = (chunk: Record<string, unknown>) => {
  return JSON.parse(
    JSON.stringify(chunk, function (_key, value) {
      return value === '' ? null : value;
    })
  );
};

export default class Api extends Service {
  @service session!: Registry['session'];
  @service currentUser!: Registry['current-user'];

  get hostname() {
    return config.API.host;
  }

  get url() {
    return `${config.API.host}/${config.API.namespace}`;
  }

  async request(endpoint: string, data?: unknown, method = 'POST'): Promise<FetchResponse> {
    const url = `${this.url}/${endpoint.replace(/^\//, '')}`;
    if (data === 'GET' || !data) {
      method = 'GET';
      data = null;
    }

    if (data === 'DELETE') {
      method = 'DELETE';
      data = null;
    }

    const headers: ApiHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    if (this.session.isAuthenticated) {
      headers.Authorization = `Bearer ${this.session.data.authenticated.token}`;
    }

    const fetchOptions: { method: string; headers: ApiHeaders; body?: string } = {
      method,
      headers,
    };

    if (method === 'POST' || method === 'PUT') {
      fetchOptions.body = JSON.stringify(data);
    }

    try {
      const result = await fetch(url, fetchOptions);

      const res: FetchResponse = {
        statusText: result.statusText,
        status: result.status,
        headers: result.headers,
        text: '',
        json: {},
        raw: result,
      };

      if (!url.includes('download')) {
        const text = await result.text();
        res.text = text;
        try {
          res.json = JSON.parse(text);
        } catch {
          // swallow error
        }
      }
      if (!result.ok) {
        if ([401].includes(res.status) && !url.includes('authentication/update-password')) {
          this.session.invalidate();
        }
        throw new FetchError(res);
      }

      return res;
    } catch (error) {
      throw new FetchError(<FetchResponse>error);
    }
  }

  @action
  async passwordResetRequest({ email }: IForgotPasswordResetRequest): Promise<FetchResponse> {
    const result = await this.request('/authentication/forgotten-password', { email });
    return result;
  }

  @action
  async passwordReset({ email, password, password_confirmation, token }: IForgotPasswordReset): Promise<FetchResponse> {
    const result = await this.request('/authentication/reset-password', {
      email,
      password,
      password_confirmation,
      token,
    });
    return result;
  }

  @action
  async registerAccount({
    email,
    password,
    password_confirmation,
    preferredName,
    pronouns,
    dateOfBirth,
    termsAgree,
    preferredTime,
    telephoneNo,
  }: IRegisterAccount): Promise<FetchResponse> {
    const result = await this.request('/authentication/register', {
      email,
      password,
      password_confirmation,
      preferredName,
      pronouns,
      dateOfBirth,
      termsAgree,
      preferredTime,
      telephoneNo,
    });
    return result;
  }

  @action
  async verifyEmail({ email, signature }: IVerifyEmail): Promise<FetchResponse> {
    const result = await this.request(`/authentication/verify/${email}?signature=${signature}`);
    return result;
  }

  @action
  async whoami(): Promise<User> {
    const result = await this.request(`/authentication/whoami`);
    return result.json as unknown as User;
  }

  @action
  async logout(refreshToken: string): Promise<void> {
    await this.request(`/authentication/logout`, { refreshToken });
  }

  @action
  async updatePassword({ password, oldPassword, password_confirmation }: IUpdatePassword): Promise<FetchResponse> {
    const result = await this.request(`/authentication/update-password`, {
      oldPassword,
      password,
      password_confirmation,
    });
    return result;
  }

  @action
  async updateProfile(chunk: Partial<Profile>): Promise<FetchResponse> {
    const result = await this.request(
      `/profiles/${this.currentUser.user.id}/client`,
      {
        ...emptyStringToNull(chunk),
      },
      'PUT'
    );
    this.currentUser.load();
    return result;
  }

  @action
  async findSlot({
    date,
    type = 'mental',
    serviceProviderId,
  }: {
    date: DateTime;
    type?: 'mental' | 'physical' | 'therapist';
    serviceProviderId?: string;
  }): Promise<SlotResponse> {
    const result = await this.request(
      `/slots/find-slot`,
      {
        date: date.toUTC().toISO(),
        type,
        serviceProviderId,
      },
      'POST'
    );
    return result.json as unknown as SlotResponse;
  }

  @action
  async lockSlot({ id }: { id: number | string }): Promise<FetchResponse> {
    const result = await this.request(`/slots/${id}/lock-slot`, 'GET');
    return result;
  }

  @action
  async unlockSlot({ id }: { id: number | string }): Promise<FetchResponse> {
    const result = await this.request(`/slots/${id}/unlock-slot`, 'GET');
    return result;
  }

  @action
  async unlockSlots(): Promise<FetchResponse> {
    const result = await this.request(`/slots/unlock-my-slots`, 'GET');
    return result;
  }

  @action
  async getCase({ cuid }: { cuid: string }): Promise<CaseModel> {
    const includes = [
      'service-provider',
      'client',
      'appointments',
      'client.profile',
      'service-provider.team-profile',
    ].join(',');
    const result = await this.request(`/cases/${cuid}?include=${includes}`, 'GET');

    return result.json as unknown as CaseModel;
    // const caseModel = Convert.toCaseModel(result.text);
    // return caseModel;
  }

  @action
  async getPaymentIntent({
    type,
    slotId,
    caseId,
  }: {
    type: 'mental' | 'physical' | 'case';
    slotId?: number;
    caseId?: string;
  }): Promise<FetchResponse> {
    const result = await this.request(
      `/purchases/payment-intent`,
      {
        type,
        ...(slotId ? { slotId } : { caseId }),
      },
      'POST'
    );
    return result;
  }

  @action
  async createAppointment({
    slotId,
    response,
  }: {
    slotId: number;
    response: Record<string, unknown>;
  }): Promise<AppointmentResponse> {
    const result = await this.request(
      `/appointments`,
      {
        slotId,
        response,
      },
      'POST'
    );
    return result.json as unknown as AppointmentResponse;
  }

  @action
  async getAppointments(): Promise<AppointmentResponse[]> {
    const result = await this.request(`/appointments`, 'GET');
    return (result.json as unknown as AppointmentResponse[]).map((appointment) => {
      return {
        ...appointment,
        start: DateTime.fromISO(<string>appointment.start),
        end: DateTime.fromISO(<string>appointment.end),
        createdAt: DateTime.fromISO(<string>appointment.createdAt),
        updatedAt: DateTime.fromISO(<string>appointment.updatedAt),
      };
    });
  }
  @action
  async getAssessments(): Promise<Assessment[]> {
    const result = await this.request(`/assessments`, 'GET');
    return result.json as unknown as Assessment[];
  }
  @action
  async getAppointment({ appointmentId }: { appointmentId: number }): Promise<AppointmentResponse> {
    const result = await this.request(`/appointments/${appointmentId}`, 'GET');
    const appointment = result.json as unknown as AppointmentResponse;

    return {
      ...appointment,
      start: DateTime.fromISO(<string>appointment.start),
      end: DateTime.fromISO(<string>appointment.end),
      createdAt: DateTime.fromISO(<string>appointment.createdAt),
      updatedAt: DateTime.fromISO(<string>appointment.updatedAt),
    };
  }

  @action
  async updateAssessment({
    assessmentId,
    questionAnswers,
  }: {
    assessmentId: string;
    questionAnswers: QuestionAnswer[];
  }): Promise<FetchResponse> {
    const result = await this.request(
      `/assessments/${assessmentId}/update-assessment`,
      {
        assessmentId,
        questionAnswers,
      },
      'POST'
    );
    return result;
  }
  @action
  async updateGadPhq({ appointmentId, gad, phq }: { appointmentId: string; gad?: Gad; phq?: Phq }) {
    const DTO: { gad?: Gad; phq?: Phq } = {};
    if (gad) DTO.gad = gad;
    if (phq) DTO.phq = phq;

    const result = await this.request(`/appointments/${appointmentId}/update-gad-phq`, DTO, 'POST');
    return result;
  }

  @action
  async updateTime({ appointmentId, slotId }: { appointmentId: string; slotId: number }) {
    try {
      const result = await this.request(`/appointments/${appointmentId}/update-time`, { slotId }, 'POST');
      return result;
    } catch (e) {
      return e;
    }
  }

  @action
  async deleteAppointment({ appointmentId }: { appointmentId: string }) {
    const result = await this.request(`/appointments/${appointmentId}`, 'DELETE');
    return result;
  }

  @action
  async getPdf({ appointmentId }: { appointmentId: string }) {
    const result = await this.request(`/appointments/${appointmentId}/download-report`, 'GET');
    return result;
  }

  @action
  async deleteAccount() {
    const result = await this.request(`/users/${this.currentUser.user.id}`, 'DELETE');
    return result;
  }

  @action
  async sendContactEmail({ name, email, reason, message, a_password }: ContactForm) {
    const result = await this.request(
      `/contact`,
      {
        name,
        email,
        message,
        reason,
        a_password,
      },
      'POST'
    );
    return result;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    api: Api;
  }
}
