import itemsImage from 'web-app/assets/images/items.png?sizes[]=300,sizes[]=600,sizes[]=1024&format=webp&rl';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Registry, service } from '@ember/service';
import styles from '../login/styles.css';

interface CreatePasswordArgs {
  email: string;
  token: string;
}

export default class CreatePassword extends Component<CreatePasswordArgs> {
  @service api!: Registry['api'];

  styles = styles;
  images = {
    items: itemsImage,
  };

  @tracked password = '';
  @tracked passwordRepeat = '';
  @tracked hasSent = false;

  validateForm() {
    const errors = [];
    if (this.password === '') errors.push('Password is required');
    if (this.passwordRepeat === '') errors.push('Repeat Password is required');
    if (this.password.length < 8) errors.push('Password must be at least 8 characters');
    if (this.password !== this.passwordRepeat) errors.push('Your passwords do not match');

    if (errors.length) {
      return { success: false, errors };
    }

    return { success: true };
  }

  get formValid() {
    return this.validateForm();
  }

  @action
  async resetPassword(formData: FormInfo): Promise<FormResult> {
    const validations = this.validateForm();
    if (validations.success) {
      await this.api.passwordReset({
        email: this.args.email,
        password: <string>formData.data.password,
        password_confirmation: <string>formData.data.passwordRepeat,
        token: this.args.token,
      });

      this.hasSent = true;
      return { success: true };
    }

    return { ...validations, message: 'Something is wrong' };
  }
}
