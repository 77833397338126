
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("web-app/app", function(){ return i("../app.ts");});
d("web-app/authenticators/custom", function(){ return i("../authenticators/custom.ts");});
d("web-app/breakpoints", function(){ return i("../breakpoints.js");});
d("web-app/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("web-app/config/environment", function(){ return i("../config/environment.js");});
d("web-app/index", function(){ return i("../index.js");});
d("web-app/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("web-app/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("web-app/initializers/ember-responsive-breakpoints", function(){ return i("../initializers/ember-responsive-breakpoints.js");});
d("web-app/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("web-app/initializers/master-tab", function(){ return i("../initializers/master-tab.js");});
d("web-app/initializers/simple-auth-token", function(){ return i("../initializers/simple-auth-token.js");});
d("web-app/instance-initializers/ember-scroll", function(){ return i("../instance-initializers/ember-scroll.js");});
d("web-app/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("web-app/instance-initializers/prerender", function(){ return i("../instance-initializers/prerender.js");});
d("web-app/instance-initializers/setup-rehydrate", function(){ return i("../instance-initializers/setup-rehydrate.js");});
d("web-app/locations/history-location", function(){ return i("../locations/history-location.js");});
d("web-app/router", function(){ return i("../router.js");});
d("web-app/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("web-app/services/analytics", function(){ return i("../services/analytics.ts");});
d("web-app/services/api", function(){ return i("../services/api.ts");});
d("web-app/services/body-class", function(){ return i("../services/body-class.js");});
d("web-app/services/cookies", function(){ return i("../services/cookies.js");});
d("web-app/services/current-user", function(){ return i("../services/current-user.ts");});
d("web-app/services/master-tab-factory", function(){ return i("../services/master-tab-factory.js");});
d("web-app/services/media", function(){ return i("../services/media.js");});
d("web-app/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("web-app/services/page-title", function(){ return i("../services/page-title.js");});
d("web-app/services/prerender", function(){ return i("../services/prerender.js");});
d("web-app/services/resize-observer", function(){ return i("../services/resize-observer.js");});
d("web-app/services/scroll", function(){ return i("../services/scroll.js");});
d("web-app/services/session", function(){ return i("../services/session.js");});
d("web-app/services/session", function(){ return i("../services/session.ts");});
d("web-app/session-stores/application", function(){ return i("../session-stores/application.js");});
d("web-app/utils/AppointmentModel", function(){ return i("../utils/AppointmentModel.ts");});
d("web-app/utils/caseModel", function(){ return i("../utils/caseModel.ts");});
d("web-app/utils/uuid-generator", function(){ return i("../utils/uuid-generator.js");});
d("web-app/templates/404", function(){ return i("../templates/404.hbs");});
d("web-app/routes/404", function(){ return i("../routes/404.ts");});
d("web-app/templates/client-policy", function(){ return i("../templates/client-policy.hbs");});
d("web-app/routes/client-policy", function(){ return i("../routes/client-policy.ts");});
d("web-app/templates/complaints-policy", function(){ return i("../templates/complaints-policy.hbs");});
d("web-app/routes/complaints-policy", function(){ return i("../routes/complaints-policy.ts");});
d("web-app/templates/contact", function(){ return i("../templates/contact.hbs");});
d("web-app/routes/contact", function(){ return i("../routes/contact.ts");});
d("web-app/templates/create-password", function(){ return i("../templates/create-password.hbs");});
d("web-app/routes/create-password", function(){ return i("../routes/create-password.ts");});
d("web-app/templates/terms-policy", function(){ return i("../templates/terms-policy.hbs");});
d("web-app/routes/terms-policy", function(){ return i("../routes/terms-policy.ts");});
d("web-app/templates/verify-email", function(){ return i("../templates/verify-email.hbs");});
d("web-app/routes/verify-email", function(){ return i("../routes/verify-email.ts");});
d("web-app/templates/organisations", function(){ return i("../templates/organisations.hbs");});



w._embroiderRouteBundles_ = [
  {
    names: ["application.index"],
    load: function() {
      return import("./_route_/application.index.js");
    }
  },
  {
    names: ["application"],
    load: function() {
      return import("./_route_/application.js");
    }
  },
  {
    names: ["authenticated.account"],
    load: function() {
      return import("./_route_/authenticated.account.js");
    }
  },
  {
    names: ["authenticated.appointments.details.confirm"],
    load: function() {
      return import("./_route_/authenticated.appointments.details.confirm.js");
    }
  },
  {
    names: ["authenticated.appointments.details","authenticated.appointments.details.index","authenticated.appointments.details.loading"],
    load: function() {
      return import("./_route_/authenticated.appointments.details.js");
    }
  },
  {
    names: ["authenticated.appointments","authenticated.appointments.index","authenticated.appointments.error","authenticated.appointments.loading"],
    load: function() {
      return import("./_route_/authenticated.appointments.js");
    }
  },
  {
    names: ["authenticated.index"],
    load: function() {
      return import("./_route_/authenticated.index.js");
    }
  },
  {
    names: ["authenticated.information"],
    load: function() {
      return import("./_route_/authenticated.information.js");
    }
  },
  {
    names: ["authenticated","authenticated.forms"],
    load: function() {
      return import("./_route_/authenticated.js");
    }
  },
  {
    names: ["dr-waheed-arian"],
    load: function() {
      return import("./_route_/dr-waheed-arian.js");
    }
  },
  {
    names: ["pathway.questionnaire"],
    load: function() {
      return import("./_route_/pathway.questionnaire.js");
    }
  },
  {
    names: ["pathway","pathway.index"],
    load: function() {
      return import("./_route_/pathway.js");
    }
  },
  {
    names: ["checkout","checkout.case","checkout.case.confirm","checkout.case.index","checkout.error"],
    load: function() {
      return import("./_route_/checkout.js");
    }
  },
  {
    names: ["forgotten-password"],
    load: function() {
      return import("./_route_/forgotten-password.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["privacy-policy"],
    load: function() {
      return import("./_route_/privacy-policy.js");
    }
  },
  {
    names: ["reset-password"],
    load: function() {
      return import("./_route_/reset-password.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"web-app","version":"0.0.1+ef5e15d5"});
}

